//eslint-disable-next-line
import store from '@/state/store'
import { format, parseISO } from "date-fns";
import _ from 'lodash'

class CustomTree extends window.gantt.views.tree {
    config() {
        let finished_mounting = false;
        // helper methods
        const showChildNode  = _.debounce(() => {
            let tree = window.webix.$$('myGanttTree');
            if(store.getters['performance/objectives/selectedGoalObjective'] == null){
                return;
            }
            let item = tree.getItem('objective-'+store.getters['performance/objectives/selectedGoalObjective'].id);
            if(!item){
                return;
            }
            while(item.$parent != 0){
                tree.open(item.$parent);    
                item = tree.getItem(item.$parent);
            }
            finished_mounting = true;
        }, 100)
        const setGanttHeight = _.debounce(() => {
            let table_header = document.querySelector('[view_id="myGanttTree"]').children[0].clientHeight;
            let table_footer = 15;
            let tree = window.webix.$$('myGanttTree');
            //let tree_height = window.webix.$$('myGanttTree').count();
            //tree_height = tree_height*24;
            let tree_height = document.querySelector('[view_id="myGanttTree"]').children[1].children[1].children[0].clientHeight;

            window.webix.$$('myGantt').config.height = tree_height + (table_header+table_footer);
            if(store.getters['performance/objectives/selectedGoalObjective'] != null){
                let node_id = `objective-${store.getters['performance/objectives/selectedGoalObjective'].id}`
                tree.showItem(node_id);
                tree.open(node_id);
            }
            tree.resize();
            showChildNode();
            
        }, 100)
        const handleFirstSelect = _.debounce(() => {
            let performance_objective_id = store.getters['performance/objectives/selectedGoalObjective'] != null
                ? store.getters['performance/objectives/selectedGoalObjective'].id 
                : -1;
            let has_gantt_items = store.state.gantt.gantt_tasks.length > 0;
            if(performance_objective_id && has_gantt_items) {
                if(window.webix.$$('myGanttTree').exists('objective-'+performance_objective_id)){
                    window.webix.$$('myGanttTree').select('objective-'+performance_objective_id);
                }
                setGanttHeight();
            }
        }, 100)



        //* ------------------------ START --------------------------------- *//
        const ui = super.config();
        //* give gantt a hardcoded id
        ui.id = 'myGanttTree';
        //* stop drag n drop 
        ui.drag = false;

        ////* hide the default '+ Add item' column
        //if(ui.columns[2]){
        //    ui.columns[2]['hidden'] = false;
        //}
        ui.columns[0].resize = false;
        

        ui.columns[1].header = "Start Date"
        ui.columns[1].format = function(value){
          let date = value;
          if(date !== ''){
              if(typeof date === 'string'){
                  date = parseISO(date);
              }
              return format(date, 'dd-MMM-yyyy');
          }
          else{
              return '';
          }
        }
        ui.on['onAfterSelect'] = function (obj){
            //* process id eg objective-221 or kr-123
            //index 0 = type, index 1 = id
            let split_id = obj.id.split('-');
            let params = {
                type: split_id[0],
                id: split_id[1],
            }
            store.dispatch('gantt/selectGanttObjectiveRow', params);

            //* only continue if type is objective !
            if(params.type != 'objective'){
                return;
            }

            //* open objective on selection
            

            // sync selection to objectives table
            setTimeout(()=>{
                let objectives_table = window.webix.$$('objectivesTableGOAL');
                if(objectives_table && objectives_table.exists(params.id)){
                    objectives_table.select(params.id);
                    setTimeout(()=>{
                        this.open(obj.id);
                    },50)
                }
            },150)
        }
        //eslint-disable-next-line
        ui.on['data->onStoreLoad'] = function (obj){
            handleFirstSelect();
        }
        ui.on['data->onStoreUpdated'] = function (){
            if( finished_mounting && finished_mounting == 'Dont progress'){
                handleFirstSelect();
            }
        };
        
        //* clone start date column
        let col = _.cloneDeep(ui.columns[1])
        col.id = "end_date"
        ui.columns.push(col);
        ui.columns[2].header = "End Date"
        ui.columns[2].format = function(value){
            let date = value;
            if(date !== ''){
                if(typeof date === 'string'){
                    date = parseISO(date);
                }
                return format(date, 'dd-MMM-yyyy');
            }
            else{
                return '';
            }
        }

        ui.columns.push({
            id: 'progress', header: "Progress", template: "#progress#%", width: 80,  resize: false,
        });

        ui.columns[3].template = function(obj, common, value){
            if(value >= 100) {
                return 100 + '%'
            } else {
                return value + '%'
            }
        }

        //* add pinning column
        // ui.columns.push(
        //     {
        //         id: 'progress',
        //         resize: false,
        //         width: 600,
        //         header: `Progress`,
        //         template: function(obj){
        //                return `<span :class="getCustomClasses" style="padding-top: 0.06rem;"> +
        //                             <div class="round-progress-bar">
        //                                 <svg width="24" height="24" viewBox="0 0 24 24">
        //                                     <circle class="progress-bar__background" cx="12" cy="12" r="10" fill="transparent" stroke="#c7c7c7"
        //                                         stroke-width="1.5"></circle>
        //                                     <circle v-if="${obj.progress} > 0" class="progress-bar__foreground" cx="12" cy="12" r="10" fill="transparent" :stroke="${obj.progress} == 100 ? '#35CA23' : '#1f44ff'"
        //                                         stroke-width="2" stroke-linecap="round" :stroke-dasharray="2 * ${Math.PI} * 10"
        //                                         :stroke-dashoffset="2 * ${Math.PI} * 10 * (1 - ${obj.progress} / 100)" transform="rotate(-95 12 12)"></circle>
        //                                     <text v-if="${obj.progress} < 100" x="50%" y="50%" text-anchor="middle" dy=".3em">${obj.progress}%</text>
        //                                 </svg>
        //                             </div>
        //                         </span>`
                    
        //         }
        //     }
        // )

        

        //* add pinning column
        ui.columns.push(
            {
                id: 'pinned_item',
                resize: false,
                width: 30,
                header: `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="thumbtack" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="pinned-gantt-item me-1 svg-inline--fa fa-thumbtack"><path fill="currentColor" d="M32 32C32 14.3 46.3 0 64 0H320c17.7 0 32 14.3 32 32s-14.3 32-32 32H290.5l11.4 148.2c36.7 19.9 65.7 53.2 79.5 94.7l1 3c3.3 9.8 1.6 20.5-4.4 28.8s-15.7 13.3-26 13.3H32c-10.3 0-19.9-4.9-26-13.3s-7.7-19.1-4.4-28.8l1-3c13.8-41.5 42.8-74.8 79.5-94.7L93.5 64H64C46.3 64 32 49.7 32 32zM160 384h64v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V384z" class=""></path></svg>`,
                template: function(obj){
                    const gantt_store = store.state.gantt;
                    if(obj.id == gantt_store.gantt_pinned_id){
                        return `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="thumbtack" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="row-pinned selected-pinned-gantt-item me-1 svg-inline--fa fa-thumbtack"><path fill="currentColor" d="M32 32C32 14.3 46.3 0 64 0H320c17.7 0 32 14.3 32 32s-14.3 32-32 32H290.5l11.4 148.2c36.7 19.9 65.7 53.2 79.5 94.7l1 3c3.3 9.8 1.6 20.5-4.4 28.8s-15.7 13.3-26 13.3H32c-10.3 0-19.9-4.9-26-13.3s-7.7-19.1-4.4-28.8l1-3c13.8-41.5 42.8-74.8 79.5-94.7L93.5 64H64C46.3 64 32 49.7 32 32zM160 384h64v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V384z" class=""></path></svg>`
                    }
                    else{
                        return `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="thumbtack" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="row-pinned pinned-gantt-item me-1 svg-inline--fa fa-thumbtack"><path fill="currentColor" d="M32 32C32 14.3 46.3 0 64 0H320c17.7 0 32 14.3 32 32s-14.3 32-32 32H290.5l11.4 148.2c36.7 19.9 65.7 53.2 79.5 94.7l1 3c3.3 9.8 1.6 20.5-4.4 28.8s-15.7 13.3-26 13.3H32c-10.3 0-19.9-4.9-26-13.3s-7.7-19.1-4.4-28.8l1-3c13.8-41.5 42.8-74.8 79.5-94.7L93.5 64H64C46.3 64 32 49.7 32 32zM160 384h64v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V384z" class=""></path></svg>`
                    }
                }
            }
        )

        //* update first columns title
        ui.columns[0].header = ["Objective / Key Result"];

        //* remove the folder/file icons for each item row
        ui.type = {};

        ui.type.folder = '';

        //eslint-disable-next-line
        ui.columns[0].template = function(obj, common, value){
            if(obj.type == 'project'){
                if(obj.objective_type == 'personal') {
                    return common.treetable(obj, common) + "<span class='badge-item badge-item-objective-personal'>"+value+"</span>";
                } else {
                    if(obj.objective_type == 'milestone') {
                        return common.treetable(obj, common) + "<span class='badge-item badge-item-objective-goal'>"+value+"</span>";
                    } else {
                        return common.treetable(obj, common) + "<span class='badge-item badge-item-objective'>"+value+"</span>";
                    }
                   
                }
            }
            else if(obj.type == 'task'){
                return common.treetable(obj, common) + "<span class='badge-item badge-item-key-result'>"+value+"</span>";
            }
            else{
                return common.treetable(obj, common) + "<span class='badge-item badge-item-key-action'>"+value+"</span>";
            }
        }

        //set 'show_details' columns -- updated-> show details is true at start

        ui.columns[1].hidden = true;
        ui.columns[2].hidden = true;
        ui.columns[3].hidden = true;
        
        //* set pinning onclick
        ui.onClick['selected-pinned-gantt-item'] = function(){
            setTimeout(()=>{// ! timeout so that table can set selected row first
                //* get id by combining type and id -> objective-112 or kr-318
                const gantt_store = store.state.gantt;
                const item_id = `${gantt_store.gantt_selected_item_type}-${gantt_store.gantt_selected_objective_id}`

                if(gantt_store.gantt_pinned_id != -1){
                    store.dispatch('gantt/setKanbanLoadingState', true);
                    store.dispatch('gantt/clearPinnedGanttItems');
                    setTimeout(()=> {
                        window.webix.$$('myGanttTree').select(item_id);
                        store.dispatch('gantt/setKanbanLoadingState', false);
                    }, 2000);
                    return;
                }
            },50)
        }
        // perform new pinning action (set pinned id and populate pinned array)
        ui.onClick['pinned-gantt-item'] = function(){
            store.dispatch('gantt/setKanbanLoadingState', true);
            setTimeout(()=>{// ! timeout so that table can set selected row first
                //* get id by combining type and id -> objective-112 or kr-318
                const gantt_store = store.state.gantt;
                const item_id = `${gantt_store.gantt_selected_item_type}-${gantt_store.gantt_selected_objective_id}`


                //* get parent node (not returned with serialize)
                let parent = _.clone(window.webix.$$('myGanttTree').getItem(item_id));
                parent.parent = 0; //*gant needs root node to have parent = 0
                
                //* only run the serialize method if the current branch has children
                let has_children = window.webix.$$('myGanttTree').data.getBranch(item_id).length > 0;
                let children = has_children
                    ? window.webix.$$('myGanttTree').data.serialize(item_id)
                    : []
                
                children.push(parent);
                store.dispatch('gantt/setPinnedGanttItems', { array_data: children, gantt_pinned_id:item_id});
                //select item with new data refresh;
                setTimeout(()=> {
                    window.webix.$$('myGanttTree').select(item_id);
                    window.webix.$$('myGanttTree')
                        .getColumnConfig('pinned_item')
                        .header = `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="thumbtack" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="selected-pinned-gantt-item me-1 svg-inline--fa fa-thumbtack"><path fill="currentColor" d="M32 32C32 14.3 46.3 0 64 0H320c17.7 0 32 14.3 32 32s-14.3 32-32 32H290.5l11.4 148.2c36.7 19.9 65.7 53.2 79.5 94.7l1 3c3.3 9.8 1.6 20.5-4.4 28.8s-15.7 13.3-26 13.3H32c-10.3 0-19.9-4.9-26-13.3s-7.7-19.1-4.4-28.8l1-3c13.8-41.5 42.8-74.8 79.5-94.7L93.5 64H64C46.3 64 32 49.7 32 32zM160 384h64v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V384z" class=""></path></svg>`;
                        window.webix.$$('myGanttTree').refreshColumns();
                        store.dispatch('gantt/setKanbanLoadingState', false);
                }, 2000)

            }, 55)
        }
        

        return ui;
    }
}

export { CustomTree as default };